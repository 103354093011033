import React from "react";
import shopCSS from './../style/shop.module.css'
import { ShopAccessories } from "./ShopAccessories";
import { ShopDresses } from "./ShopDresses";
import { ShopWeekend } from "./ShopWeekend";


export const Shop = ({contentRef}:{contentRef:any}) => {



  return(
    <div ref={contentRef} className={shopCSS.holder}>
      <h1>Parduotuvė</h1>
      <ShopDresses />
      <ShopAccessories />
      <ShopWeekend />
      
    </div>
  )
}