import React from "react";
import contactsCSS from './../style/contacts.module.css'
import { TiPhoneOutline } from "react-icons/ti";
import { TiMail } from "react-icons/ti";
import { TiSocialInstagram } from "react-icons/ti";
import { TiSocialFacebookCircular } from "react-icons/ti";

export const Contacts = ({contentRef}:{contentRef:any}) => {
  return(
    <div className={contactsCSS.fullWidth}>
      <div ref={contentRef} className={contactsCSS.holder}>
        <h1>Susisiekime</h1>

        <div className={contactsCSS.rowHolder}>
          <div className={contactsCSS.itemHolder}>
              <div className={contactsCSS.iconHolder}><a href="tel:+37068739203"><TiPhoneOutline size={40}/></a></div>
              <div className={contactsCSS.infoHolder}>
                <div className={contactsCSS.infoValue}><a href="tel:+37068739203">+370 68739203</a></div>
                <div className={contactsCSS.infoTitle}>Telefonas</div>
              </div>
          </div>
          <div className={contactsCSS.itemHolder}>
              <div className={contactsCSS.iconHolder}><a href="mailto:info@linn.lt"><TiMail size={40}/></a></div>
              <div className={contactsCSS.infoHolder}>
                <div className={contactsCSS.infoValue}><a href="mailto:info@linn.lt">info@linn.lt</a></div>
                <div className={contactsCSS.infoTitle}>El. paštas</div>
              </div>
          </div>        
        
        </div>

        <div className={contactsCSS.rowHolder}>
          <div className={contactsCSS.itemHolder}>
              <div className={contactsCSS.iconHolder}><a href="https://www.instagram.com/linn.lt/"><TiSocialInstagram size={40}/></a></div>
              <div className={contactsCSS.infoHolder}>
                <div className={contactsCSS.infoValue}><a href="https://www.instagram.com/linn.lt/">@linn.lt</a></div>
                <div className={contactsCSS.infoTitle}>Instagram</div>
              </div>
          </div>
          <div className={contactsCSS.itemHolder}>
              <div className={contactsCSS.iconHolder}><a href="https://www.facebook.com/Linn.lt.lt"><TiSocialFacebookCircular size={40}/></a></div>
              <div className={contactsCSS.infoHolder}>
                <div className={contactsCSS.infoValue}><a href="https://www.facebook.com/Linn.lt.lt">@linn.lt.lt</a></div>
                <div className={contactsCSS.infoTitle}>Facebook</div>
              </div>
          </div>        
        
        </div>

        <div className={contactsCSS.copyRight}>
        Linn © 2022. Be raštiško sutikimo turinį kopijuoti draudžiama
        </div>
      </div>
    </div>
  )
}