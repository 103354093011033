
import React, { useRef } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import './App.css'

import { MainHeader } from './components/MainHeader';
import { Intro } from './components/Intro';
import { AboutMe } from './components/AboutMe';
import { Gallery } from './components/Gallery';
import { Shop } from './components/Shop';
import { Contacts } from './components/Contacts';

const App = () =>  {
  
  const contentRefs = {
    home: useRef<HTMLDivElement>(null),
    shop: useRef<HTMLDivElement>(null),
    gallery: useRef<HTMLDivElement>(null),
    contacts: useRef<HTMLDivElement>(null)
  }

  const refClickAction = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="App" ref={contentRefs.home}>
        <MainHeader contentRefs={contentRefs} refClickAction={refClickAction}/>
        <ParallaxProvider>
          <Intro />
          <AboutMe />
          <Shop contentRef={contentRefs.shop}/>
          <Gallery contentRef={contentRefs.gallery}/>
          <Contacts  contentRef={contentRefs.contacts} />
        </ParallaxProvider>

    </div>
  );
}


export default App;


