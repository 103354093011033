import accessoryImagesData from './shopImagesAccessories.json';
import dressImagesData from './shopImagesDresses.json';
import weekendImagesData from './weekendImages.json';

export const accessoryImages = () => {
  
  
  return alterInfo(accessoryImagesData)
}

export const dressImages = () => {
  
  
  return alterInfo(dressImagesData)
}

export const weekendImages = () => {
  
  
  return alterInfo(weekendImagesData)
}

const alterInfo = (data:any) => {
  
  for (let i = 0; i < data.length; i++) {
    const fullName = data[i].src;
    const split1 = fullName.split("/")
    const name = split1[split1.length - 1];

    const nameSplit = name.split("-");

    const title = nameSplit[0];
    const price = nameSplit[1];
    
    data[i] = {...data[i],title, price}
  };
  return data
}