import React, { useEffect, useState } from "react";
// import shopCSS from './../style/shop.module.css'
import { Gallery as GridGallery } from "react-grid-gallery";
import { weekendImages } from "./../data/shopImages";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export const ShopWeekend = () => {
  const [index, setIndex] = useState(-1 as number)

  const [ images, setImages ] = useState([] as any)

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index: number) => { 
    setIndex(index)
  };
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);


  useEffect(()=>{
    const imageData = weekendImages()

    for (let i = 0; i < imageData.length; i++) {
     
      imageData[i] = {...imageData[i]}
    }

    setImages(imageData)
  },[])

  
  return(
    <div >
      <h2>Laisvalaikio rūbai</h2>
      <GridGallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
        rowHeight={300}
      />
      {index >= 0 && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  )
}