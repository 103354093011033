import React, { useEffect, useState } from "react";
// import shopCSS from './../style/shop.module.css'
import { Gallery as GridGallery } from "react-grid-gallery";
import { dressImages } from "./../data/shopImages";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export const ShopDresses = () => {
  const [index, setIndex] = useState(-1 as number)

  const [ images, setImages ] = useState([] as any)

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index: number) => { 
    setIndex(index)
  };
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);


  const ThumbnailCaption = (title:any,price:any) => {
    return(
      <div><b>{title}</b> {price}€</div>
    )
  }

  useEffect(()=>{
    const imageData = dressImages()

    for (let i = 0; i < imageData.length; i++) {
      const price = imageData[i].price
      const title = imageData[i].title
   
      imageData[i] = {...imageData[i], thumbnailCaption: ThumbnailCaption(title,price)}
    }

    setImages(imageData)
  },[])

  
  return(
    <div >
      <h2>Suknelės</h2>
      <GridGallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
        rowHeight={300}
      />
      {index >= 0 && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  )
}