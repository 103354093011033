import React, { useRef } from 'react'
import headerCSS from './../style/header.module.css'
import { TiThMenu } from "react-icons/ti";

export const MainHeader = ({contentRefs, refClickAction}:{contentRefs:any, refClickAction:any}) => {

  const navRef = useRef<HTMLDivElement>(null);

  const handleNavClick = (ref:any) => {
    refClickAction(ref)
    const wwidth = window.innerWidth;
  
    if( navRef.current && wwidth <= 500){
      
      navRef.current.style.opacity = "0"
      navRef.current.style.visibility = "hidden"
    }
  }

  const handleBurgerClick = () => {
      

      const computedStyle = navRef.current ? window.getComputedStyle(navRef.current) : false;
      if(computedStyle){
        const display = computedStyle.getPropertyValue("visibility");
  
        if( navRef.current){
          navRef.current.style.visibility = display === "hidden" ? "visible" : "hidden"
          navRef.current.style.opacity = display === "hidden" ? "1" : "0"
          
        }
      }
      
    
  }

  return(
    <header className={headerCSS.header}>
      <div className={headerCSS.holder}>
      <div className={headerCSS.logo} onClick={() => refClickAction(contentRefs.home)} ><img alt="LINN" src="linn-logo.png" /></div>
      <nav className={headerCSS.navMobile} onClick={handleBurgerClick}>
        
        <TiThMenu size={25}  />
      </nav>
      <nav className={headerCSS.nav} ref={navRef}>
        
        <button className='link-style' onClick={() => handleNavClick(contentRefs.shop)}>Parduotuvė</button>
        <button className='link-style' onClick={() => handleNavClick(contentRefs.gallery)}>Galerija</button>
        <button className='link-style' onClick={() => handleNavClick(contentRefs.contacts)}>Kontaktai</button>
      </nav>
      </div>
    </header>
  )
}

