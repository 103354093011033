import React from "react";
import aboutCss from './../style/about.module.css'

export const AboutMe = () => {
  return(
    <div className={aboutCss.holder}>
      <h1>Linn - daugiau Tavęs</h1>
      <p>
        LINN - tai prekinis ženklas, siūlantis autentiškus bei išskirtinius modelius millennial moteriai. Didžioji dalis LINN produkcijos yra ganėtinai universali, kuomet derinant skirtingą avalynę bei aksesuarus, galima išgauti skirtingus įvaizdžius bei dėvėti drabužius įvairiomis progomis- ypatingų švenčių metu ar tiesiog kasdien. Nepaneigiamas faktas, kad drabužiai visgi turi įtakos jausenai, laikysenai bei žingsnio tvirtumui, todėl norime Jums pasiūlyti produktus, kurie padėti pasijausti šiek tiek labiau savimi.
      </p>
      <h2>Autoriaus žodis:</h2>
      <p>
      “Jau nuo pat vaikystės puoselėjau meilę suknelėms, besisuskančiam sijonui ir subtiliems aksesuarams, kuomet pradėjau suprasti, kiek daug moteriai duoda smulkios aprangos detalės, tinkamai pasirinkti aprangos siluetai ir kaip išryškėja vidinis jausmas to pasekoje- pasikeičia moters laikysena, atsiranda daugiau švytėjimo ir drąsos.
      <br/>Ilgainiui- suknelės tapo nepaneigiama mano gyvenimo dalimi darant dienas šiek tiek labiau ypatingas. Noriu pasidalinti šiuo jausmu su Jumis”
      <br/>– Alina

      </p>
    </div>
  )
}