import React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import introCSS from './../style/intro.module.css'

export const Intro = () =>{

  return(
    <div className={introCSS.holder}>
       <ParallaxBanner
          layers={[
            { image: '/static/core/main.jpg', speed: -25 },
            // { image: '/static/core/main-slogan.png', speed: -10 },
            {
              children: <div><img alt="Nuostabios suknelės" src="/static/core/main-slogan.png"/></div>,
              speed: -22,
            }
            
            
          ]}
          className={introCSS.mainObject}
        />
    </div>
  )
}