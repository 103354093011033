import React, { useState } from "react";
import galleryCSS from './../style/gallery.module.css'

import { Gallery as GridGallery } from "react-grid-gallery";

import { images } from "./../data/galleryImages";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export const Gallery = ({contentRef}:{contentRef:any}) => {

  const [index, setIndex] = useState(-1 as number)
  
  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index: number) => { 
  
    setIndex(index)
  };
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  
  return(
    <div ref={contentRef}  className={galleryCSS.holder}>
      <h1>Galerija</h1>
      <GridGallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
        rowHeight={200}
      />
      {index >= 0 && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  )
}